
#newsletter__wrapper {
    /* Hide newsletter signup for now */
    display: none;
}
#newsletter__header {
    padding-bottom: 1.25rem;
}

/* #newsletter__wrapper {
    padding-top: 3rem; 
    padding-left: 1.25rem;
    padding-bottom: 2rem;
    position: relative;
} */

#newsletter-submit-button {
    background-color: black;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    cursor: pointer;
}
#newsletter-submit-button:hover {
    background-color: #077491;
}
#newsletter-submit-button:active {
    color: #000000;
    background-color: #FAD358;
}

#newsletter-signup-email-field {
    background-color: white;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0.75rem;
    border-color: black;
    border-width: 1px;
    width: 70%;
    min-width: 13rem;
    max-width: 25rem;
}

#newsletter-confirmation-text {
    width: 80%;
    min-width: 18rem;
    max-width: 30rem;
    padding-top: .75rem;
}

#newsletter-privacy-text {
    width: 80%;
    min-width: 18rem;
    max-width: 30rem;
    padding-top: .75rem;
    opacity: .8;  
}

#newsletter-privacy-text a:hover {
    color: #FAD358;
}

#newsletter-privacy-text a:active {
    color: #FAF1D5;
}

@media (min-width: 600px) {
    #newsletter__wrapper {
        padding-left: 3rem;
    }
}

@media (min-width: 769px) {
    #newsletter__wrapper {
        padding-top: 3rem; 
        padding-left: 5rem;
    }
}

@media (min-width: 1152px) {
    #newsletter__wrapper {
        float: left;
        padding-left: 0rem;
    }
    #newsletter-signup-email-field {
        min-width: 7.5rem;
        max-width: 7.5rem;
    }
    #newsletter-privacy-text {
        min-width: 11.5rem;
        max-width: 11.5rem;
    }
    #newsletter-confirmation-text {
        min-width: 11.5rem;
        max-width: 11.5rem;
    }
        
}