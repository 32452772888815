#finalists {
    background-color: #FAF1D5;
    position: relative;
}

#finalist__display_wrapper {
    padding-bottom: 2rem;
}

#finalists__header {
    padding: 2rem 1.25rem 0rem;
    margin-bottom: 1.25rem;
}

#finalists__menu {
    margin: 1.25rem 1.25rem;
    position: relative;
}

#finalists__menu--spinner {
    display: flex;
    background: #FAD358;
    border: 1px solid #23454E;
}

#finalists__menu--spinner-text {
    flex: auto;
    width: 100%;
    padding: 1rem;
}

#finalists__menu--spinner-icon-more, #finalists__menu--spinner-icon-less {
    flex: auto;
    margin-right: 1rem;
}

#finalists__menu--nav {
    position: absolute;
    width: 100%;
    background-color: #FAD358;
    color: #000000;
    z-index: 1;
}

#finalists__menu--nav ul {
    margin: 0;
    padding: 0;
}

#finalists__menu--nav li { 
    margin: 0;
    border-left: 1px solid #23454E;
    border-right: 1px solid #23454E;
    border-bottom: 1px solid #23454E;
    padding-left: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    cursor: pointer;
}

#finalists__cards {
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
}

#finalists__back-to-top-button--container {
    display: flex;
    justify-content: flex-end;
    margin: 1.25rem .75rem 0rem;
}

#finalists__back-to-top-button {
    display: flex;
    column-gap: .25rem;
    border: 1px solid #23454E;
    color: #23454E;
    background-color: #FAD358;
    padding: .5rem .5rem;
    margin-right: .75rem;
    cursor: pointer;
}

#finalists__back-to-top-button--text {
    width: 100%;
}

@media (min-width: 769px) {
    #finalists__header {
        padding: 3rem 5rem 0rem 5rem;
        margin-bottom: 2rem;
    }

    #finalists__back-to-top-button--container,
    #finalists__menu {
        display: none;
    }

    #finalists__cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        margin: 1rem;
    }
}