#accessibility-page-header-title {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}

@media (min-width: 1152px) {
    #accessibility-page-container {
        padding-right: 5rem;
        padding-left: 5rem;
        padding-bottom: 5rem;
    }

    #contents-container {
        display: flex;
    }

    #contact-info-container {
        display: block;
        padding-right: 3rem;
    }

    #accessibility-page-footer-border {
        display: none
    }
}

@media (max-width:1151px) {
    #accessibility-page-container {
        padding-right: 5rem;
        padding-left: 5rem;
        padding-bottom: 5rem;
    }

    #contents-container {
        display: flex;
        flex-direction: column-reverse;
    }

    #contact-info-container {
        display: block;
    }

    #accessibility-page-footer-border {
        border: 1px solid #23454E;
        margin-top: 2rem;
    }
}

@media (max-width: 769px) {
    #accessibility-page-container {
        margin-right: 1.25rem;
        padding-left: 1.25rem;
        padding-bottom: 5rem;
    }

    #contents-container {
        display: flex;
        flex-direction: column-reverse;
    }

    #contact-info-container {
        display: block;
    }

    #accessibility-page-footer-border {
        border: 1px solid #23454E;
        margin-top: 2rem;
    }
}
