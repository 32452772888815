#hero-footer {
    color: #FAD358;
    background-color: #23454E;
    position: relative;
}

#hero-footer-wrapper {
    padding: 1.25rem 1.25rem 1.25rem;
}

@media (min-width: 600px) {
    #hero-footer-wrapper {
        padding: 1.25rem 3rem 1.25rem;
    }
}

@media (min-width: 769px) {
    #hero-footer-wrapper {
        padding: 1.25rem 5rem 2rem;
    }

    #hero-footer{
        color: #FAD358;
        background-color: #23454E;
    }
}
