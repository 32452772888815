#how-awards-given__wrapper {
    position: relative;
    padding: 2rem 1.25rem 0rem;
}

#how-awards-given__header {
    padding-bottom: 1.25rem;
}

#how-awards-given__faq-link {
    opacity: .9;
}

#how-awards-given__faq-link:hover {
    color: #FAD358;
}

#how-awards-given__faq-link:active {
    color: #FAF1D5;
}

@media (min-width: 600px) {
    #how-awards-given__wrapper {
        padding: 2rem 3rem 0rem;
    }
}

@media (min-width: 769px) {
    #how-awards-given {
        float: left;
    }

    #how-awards-given__wrapper {
        padding-top: 3rem; 
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

@media (min-width: 1152px) {
    #how-awards-given__wrapper {
        float: left;
        max-width: 68%;
        padding-bottom: 3rem;
    }
}