#finalist-info {
    padding-bottom: 5rem;
}

.finalist-info-hero {
    padding: 1.25rem;
    background-color: #23454E;
}

.finalist-info-hero-name {
    margin-bottom: .125rem;
    color: #FAD358;
}
.finalist-info-hero-location {
    margin-bottom: .625rem;
    color: #FFFFFF;
}
.finalist-info-hero-title {
    color: #FFFFFF;
}

.finalist-info-bio {
    padding: 2rem 1.25rem 2rem;
    background-color: #FFFFFF;
}

.finalist-info-bio-footer {
    padding: 0 1.25rem;
    display: flex;
    column-gap: 2rem;
}

.finalist-photo-credit {
    display: none;
}

.finalist-info-bio-footer h4 {
    margin-bottom: .25rem;
}

.finalist-info-bio-footer p {
    width: 8rem;
}

@media (min-width: 600px) {

    .finalist-info-wrapper {
        border-right: 1px solid #23454E;
    }

    .finalist-info-bio {
        padding: 2rem 4rem 2rem;
    }

    .finalist-info-bio-footer {
        padding: 0 4rem;
    }

    .finalist-info-hero {
        padding: 1.25rem 4rem;
    }
}

@media (min-width: 930px) {
    .finalist-info-hero #hero-video{
        padding-top: 2rem;
    }

    .finalist-info-video-wrapper {
        padding: 2rem 3rem 0;
    }
}