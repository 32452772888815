#footer {
    position: relative;
    color: #FFFFFF;
    background-color: #000000;
    padding: 2rem 1.25rem;
}

#footer a:hover,
#footer button:hover {
  color: #FAD358;
}

#footer a:active,
#footer button:active {
  color: #FAF1D5;
}

#footer__container--upper {
    display: flex;
    flex-direction: column;
}

#footer__container--logo-site-links {
    display: flex;
    margin-bottom: 1.625rem;
    justify-content: space-between;
}

#footer__logo {
    width: 6rem;
    margin-right: 3rem;
    align-self: flex-start;
}

#footer__container--site-links {
    display: flex;
    flex-direction: column;
}
#footer__container--site-links a,
#footer__container--site-links button {
    flex: auto;
    padding-bottom: .625rem;
}

#footer__container--social-media-icon-links {
    display: flex;
    column-gap: .75rem;
}

#footer__horizontal-rule {
    border-top: 2px solid #ffffff;
    margin: 1.625rem 0rem;
}

#footer__container--commons-copyright {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
}

#footer__container--creative-commons-icons {
    display: flex;
    column-gap: 0.375rem;
    margin-bottom: .75rem;
}

#footer__container--lower {
    display: flex;
    flex-direction: column;
}

#footer__container--creative-commons-icons,
#footer__container--copyright {
    margin-bottom: .75rem;
}

#footer__container--creative-commons-icons:hover {
    filter: brightness(0) saturate(100%) invert(78%) sepia(38%) saturate(643%) hue-rotate(356deg) brightness(105%) contrast(96%);
}

#footer__container--creative-commons-icons:active {
    filter: brightness(0) saturate(100%) invert(100%) sepia(34%) saturate(4312%) hue-rotate(295deg) brightness(129%) contrast(96%);
}

#footer__container--compliance-site-links {
    display: flex;
    column-gap: .5rem;
}

#footer-social-media-icon:hover {
    filter: brightness(0) saturate(100%) invert(78%) sepia(38%) saturate(643%) hue-rotate(356deg) brightness(105%) contrast(96%);
}

#footer-social-media-icon:active {
    filter: brightness(0) saturate(100%) invert(100%) sepia(34%) saturate(4312%) hue-rotate(295deg) brightness(129%) contrast(96%);
}

#ccWrapper{
    margin: 0 auto 0 0;
}

@media (min-width: 600px) {
    #footer {
        padding: 2rem 3rem;
    }
}

@media (min-width: 769px) {
    #footer {
        padding: 3rem 2rem 1.625rem;
    }

    #footer__container--upper {
        flex-direction: row;
        justify-content: space-between;
        font-size: .625rem;
    }

    #footer__container--lower {
        font-size: .5rem;
    }

    #footer__container--logo-site-links {
        justify-content: space-between;
    }
}

@media (min-width: 940px) {
    #footer {
        padding: 3rem 5rem 1.625rem;
    }
}


@media (min-width: 1124px) {
    #footer__container--lower {
        flex-direction: row;
        justify-content: space-between;
    }

    #footer__container--commons-copyright {
        flex-direction: row;
    }
}