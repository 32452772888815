.finalist-card {
    position: relative;
    color: #000000;
    display: flex;
    margin: 0 .75rem;
    transition: transform .25s;
}

.finalist-card__image-container {
    display: flex;
    min-width: fit-content;
    border-top: 1px solid #23454E;
    border-left: 1px solid #23454E;
    border-bottom: 1px solid #23454E;
}

.finalist-card__image-container img{
    object-fit: cover;
    width: 100%;
}

.finalist-card__text-container {
    display: flex;
    flex-flow: column nowrap;
    flex: auto;
    justify-content: space-between;
    padding: .75rem .625rem;
    background: #FAD358;
    border: 1px solid #23454E;
}

.finalist-card__text-location {
    color: #555555;
    padding-bottom: .375rem;
}

.finalist-card__text-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.finalist-card__more-info-link-container {
    display: flex;
    column-gap: 0.125rem;
} 

.finalist-card__more-arrow {
    visibility: hidden;
}

.finalist-card:hover .finalist-card__more-arrow {
    visibility: visible;
}

.undecorated-link {
    text-decoration: none;
}

.filler {
    visibility: hidden;
}

@media(min-width: 769px) {
    .finalist-card {
        flex-flow: column;
        margin: auto;
    }

    .finalist-card__image-container {
        border-right: 1px solid #23454E;
        border-bottom: 0px;
    }

    .finalist-card__text-container {
        height: 7.75rem;
    }
}
