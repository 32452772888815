#super-header {
    position: fixed;
    height: 42px;
    width: 100%;
    left: 0px;
    top: 0px;
    background-color: #23454E;
    color: #ffffff;
    z-index: 99;
    padding-left: 5rem;
    padding-right: 5rem;
}

#osf-logo{
    display: inline;
}