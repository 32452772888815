#contact-info-card-container {
    width:100%;
}

#last-updated-info-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
}


#contact-information-text-container {
    display: block;
}

#contact-address-recipient {
    width: max-content;
}

#last-updated-header {
    margin-bottom: .125rem;
}

#contact-info-header {
    margin-bottom: .125rem;
}