#header {
    position: fixed;
    top: 42px;
    left: 0px;
    width: 100%;
    background-color: #FAD358;
    color: #000000;
    border-bottom: 1px solid #23454E;
    z-index: 99;
    padding-left: 5rem;
    padding-right: 5rem;
}

#main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#main-nav__links {
    display: flex;
    column-gap: 1.25rem;
}

#main-nav a {
    flex: auto;
}