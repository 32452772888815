#super-header__mobile {
    position: fixed;
    width: 100%;
    height: 32px;
    left: 0px;
    top: 0px;
    background-color: #23454E;
    color: #ffffff;
    z-index: 99;
    padding-left:  1.25rem;
}

#osf-logo {
    height: 0.5rem;
    margin: 0.625rem 0;
}
