#get-in-touch {
    color: #FFFFFF;
    width: 100%
}

#contact-information-text-container {
    margin-top: 0.375rem;
    margin-bottom: 1.5rem;
}

@media (min-width: 769px) {
    #get-in-touch-content {
        font-size: .5rem;
    }
}