#awardee-share-social-media-icon {
    filter: brightness(0) saturate(100%) invert(7%) sepia(0%) saturate(1608%) hue-rotate(138deg) brightness(102%) contrast(87%);
}

#awardee-share-social-media-icon:hover {
    filter: brightness(0) saturate(100%) invert(33%) sepia(23%) saturate(4785%) hue-rotate(166deg) brightness(87%) contrast(94%);
}

#awardee-share-social-media-icon:active {
    filter: brightness(0) saturate(100%) invert(27%) sepia(59%) saturate(356%) hue-rotate(145deg) brightness(94%) contrast(92%);
}

#awardee-share-container-social-media-icon-links {
    display: flex;
    column-gap: .75rem;
}

#awardee-share-header {
    flex: auto;
}