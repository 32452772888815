:root { 
  --pure-black: #000000;
  --dark-gray: #212121;
  --mid-gray: #404040;
  --sky-blue: #23454E;
  --light-sky-blue: #077491;
  --white: #FFFFFF;
  --beige: #FAF1D5;
  --amber-yellow: #FAD358;
}

:focus {
  outline: #0099CC solid 2px;
}

@font-face {
  font-family: "GT America Black";
  src: url("./fonts/GTAmerica/GT-America-Compressed-Black.otf") format("opentype");
}

@font-face {
  font-family: "GT America Bold";
  src: url("./fonts/GTAmerica/GT-America-Compressed-Bold.otf") format("opentype");
}

@font-face {
  font-family: "GT America Medium";
  src: url("./fonts/GTAmerica/GT-America-Compressed-Medium.otf") format("opentype");
}

@font-face {
  font-family: "GT America Regular";
  src: url("./fonts/GTAmerica/GT-America-Standard-Regular.otf") format("opentype");
}

@font-face {
  font-family: "GT America Standard Bold";
  src: url("./fonts/GTAmerica/GT-America-Standard-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Lyon Text Regular";
  src: url("./fonts/Lyon/lyon_text/LyonText-Regular.otf") format("opentype");
}

html {
  font-size: 1.125rem;
}

#content {
    padding-top: 89px;
    width: 100%;
}

.paragraph-margin {
  margin-bottom: 1rem;
}

.container {
  width: 100%;
  max-width: 1160px;
  margin: auto;
}

.container--wider {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.section-anchor {
  position: absolute;
  top: -4.5rem; 
  left: 0;
}

.header-xl {
  font-family: "GT America Black";
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.125;
}

.header-l {
  font-family: "GT America Bold";
  text-transform: uppercase;
  font-size: 1.625rem;
  line-height: 1.125;
}

.header-m {
  font-family: "GT America Bold";
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 1.4;
}

.header-s {
  font-family: "GT America Medium";
  text-transform: uppercase;
  font-size: .875rem;
  line-height: 1.4;
}

.card-title {
  font-family: "GT America Bold";
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 1.4;
}

.text-label {
  font-family: "GT America Medium";
  text-transform: uppercase;
  font-size: .875rem;
  line-height: 1.4;
}

.faq-question {
  font-family: "GT America Standard Bold";
  font-size: 1.125rem;
  line-height: 1.625rem;
}

.nav-text {
  font-family: "GT America Regular";
  font-size: .675rem;
  line-height: 1.25;
}

.nav-text--mobile {
  font-family: "GT America Regular";
  font-size: 1.25rem;
  line-height: 1.1;
}

.support-text-xl {
  font-family: "GT America Regular";
  font-size: .825rem;
  line-height: 1.4;
}

.support-text-l {
  font-family: "GT America Regular";
  font-size: .75rem;
  line-height: 1.4;
}

.support-text-l_reduced_line_height {
  font-family: "GT America Regular";
  font-size: .75rem;
  line-height: 1;
}

.support-text-m {
  font-family: "GT America Regular";
  font-size: .625rem;
  line-height: 1.4;
}

.support-text-s {
  font-family: "GT America Regular";
  font-size: .5rem;
  line-height: 1.4;
}

.body-text {
  font-family: "Lyon Text Regular";
  font-size: 1rem;
  line-height: 1.4;
  min-width: 18rem;
  max-width: 30rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

i,
em {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

img { 
  display: block;
}

.button-link {
  text-decoration: underline;
  cursor: pointer;
}

.button-link-undecorated {
  text-decoration: none;
  cursor: pointer;
}

.button-link-undecorated:hover {
  text-decoration: underline;
}

.multiline {
  white-space: pre-wrap;
}

.__closed {
    display: none;
}

.clear {
  clear: both;
}

.cookie-button {
  padding: .5rem .75rem;
}

.loading {
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  background-color: #23454E;
  padding: 1rem 0;
}

.support-page-header-horizontal-rule {
  border-top: 4px solid #23454E;
}

.support-page-header-text {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.support-page-body-text {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.support-page-list-item {
  display: list-item;
  list-style-position: inside;
  list-style-type: decimal;
}

.support-page-unordered-list-item {
  display: list-item;
  list-style-position: inside;
  list-style-type: disc;

}

@media (min-width: 769px) {
  html {
    font-size: 1.5rem;
  }
  #content {
      padding-top: 118px;
  }
  .header-xl {
    font-size: 2.625rem;
  }
  .header-l {
    font-size: 2rem;
  }
  .header-m {
    font-size: 1.625rem;
  }
  .header-s {
    line-height: 1.125;
  }
  .card-title {
    font-size: .875rem;
    line-height: 1.125;
  }
  .text-label {
    font-size: .625rem;
    line-height: 1.125;
  }
  .body-text {
    min-width: 20rem;
    max-width: 30rem;
  }
}
