#faq-page-header-title {
    margin-top: 3rem;
    margin-bottom: 1.25rem;
}

#faq-contents-container {
    display: flex;
    flex-flow: column nowrap;
}

#faq-section-border {
    border: 1px solid #24697C;
    transform: rotate(0deg);
}

#contact-info-card {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

@media (min-width: 1032px) {
    #frequently-asked-questions-page-container {
        padding-right: 5rem;
        padding-left: 5rem;
        margin-bottom: 5rem;
    }

    #faq-section-arrow {
        display: none;
    }

    .faq-section-contents-hide {
        display: flex;
        margin-top: 2rem;
        margin-bottom: 2rem;
        column-gap: 3rem;
    }

    .faq-section-contents-show {
        display: flex;
        margin-top: 2rem;
        margin-bottom: 2rem;
        column-gap: 3rem;
    }

    .faq-section-question {
        min-width: 15rem
    }

    .faq-section-question-show {
        min-width: 15rem
    }
}

@media (min-width: 769px) and (max-width: 1031px) {
    #frequently-asked-questions-page-container {
        padding-right: 5rem;
        padding-left: 5rem;
        margin-bottom: 5rem;
    }

    .faq-section-contents-show {
        margin-bottom: 2rem;
    }

    .faq-section-answer-hide {
        display: none;
    }

    .faq-section-question {
        padding-top: 1.25rem;
        padding-bottom: 0.75rem;
        display: flex;
        column-gap: 1rem;
        justify-content: space-between;
        cursor: pointer;
    }

    .faq-section-question-show {
        display: flex;
        justify-content: space-between;
        column-gap: 1rem;
        padding-top: 1.25rem;
        padding-bottom: 1rem;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    #frequently-asked-questions-page-container {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        margin-bottom: 5rem;
    }

    .faq-section-contents-show {
        margin-bottom: 2rem;
    }

    #faq-section-arrow {
        display: inline;
    }

    .faq-section-answer-show {
        margin-right: 4rem;
    }

    .faq-section-answer-hide {
        display: none;
        margin-right: 4rem;
    }

    .faq-section-question {
        padding-top: 1.25rem;
        padding-bottom: 0.75rem;
        column-gap: 1rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    .faq-section-question-show {
        padding-top: 1.25rem;
        padding-bottom: 1rem;
        column-gap: 1rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
}