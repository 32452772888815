#about {
    color: #FFFFFF;
    background-color: #23454E;
}

#about-wrapper {
    padding: 0rem 1.25rem 3rem;
}

@media (min-width: 600px) {
    #about-wrapper {
        padding: 0rem 3rem 3rem;
    }
}

@media (min-width: 769px) {
    #about-wrapper {
        padding: 0rem 5rem 3rem;
    }
}