#hero-video {
    background-color: #23454E;
}

.hero-container {
    width: 100%;
    max-width: 1160px;
    margin: auto;
    border: 1px solid #23454E;
}

#hero-video-wrapper {
    background-color: #23454E;
    position: relative;
    padding-top: 56.25%;
    z-index: 0;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.react-player__preview:hover .react-player__play-icon {
    content: url('../assets/btn_play_hover.svg');
}

.react-player__play-icon {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    content: url('../assets/btn_play_default.svg');
}

.react-player__play-icon:hover {
    content: url('../assets/btn_play_hover.svg');
}

@media (min-width: 769px) {
    .hero-container {
        padding: 2rem 5rem 0;
    }
    #hero-video-wrapper {
        padding-top: 56.25%; 
    }
}