#finalist-page {
    background-color: #FAF1D5;
}

#finalist-page-container {
    display: flex;
    flex-direction: column;
    margin-top: 42px;
    max-width: 1440px;
}

#finalist-page-menu--mobile {
    position: fixed;
    top: 89px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0;
    background-color: #FAD358;
    border-bottom: 1px solid #23454E;
    z-index: 3;
}

#finalist-page-menu__dropdown {
    position: fixed;
    top: 131px;
    background-color: #FAD358;
    width: 100%;
    z-index: 2;
    overflow: scroll !important;
    max-height: 80vh;
}

#finalist-page-menu__dropdown li {
    margin: 0;
    border-bottom: 1px solid #23454E;
}

#finalist-page-menu__dropdown a {
    display: block;
    margin: 0;
    padding-left: 1.25rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
}

#finalist-page-menu {
    display: none;
}

#finalist-page-menu__spinner {
    border-right: 1px solid #23454E;
    padding: .5rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.finalist-page-menu-padding {
    padding: .5rem 1.25rem;
}


#finalist-page-profile {
    background-color: #ffffff;
}

@media (min-width: 769px) {
    #finalist-page-menu--mobile {
        top: 118px;
    }

    #finalist-page-container {
        margin-top: 49px;
    }

    #finalist-page-menu__spinner {
        padding: .5rem 1.25rem .5rem 5rem;
    }
    
    #finalist-page-menu__dropdown {
        top: 167px;
    }

    #finalist-page-menu__dropdown a {
        padding-left: 5rem;
    }
}

@media (min-width: 930px) {
    #finalist-page-container {
        flex-direction: row;
        margin-top: 0;
    }
    #finalist-page-menu--mobile {
        display: none;
    }

    #finalist-page-menu {
        display: flex;
        flex-direction: column;
        flex: 1 0 100px;
        padding: 3rem 2rem;
        background-color: #FAF1D5;
        border-right: 1px solid #23454E;
    }

    #finalist-page-menu__dropdown {
        visibility: hidden;
    }

    #finalist-page-menu h1 {
        margin-bottom: 1rem;
    }

    #finalist-page-menu li {
        margin-bottom: .5rem;
    }

    #finalist-page-profile {
        flex: 5;
    }

    .selected {
        text-decoration: none;
        font-weight: 600;
        color: #000000;
    }
}

@media (min-width: 1700px) {
    #finalist-page-menu {
        padding: 3rem 2rem 3rem 0;
    }
}