#cc_div p,
#cc_div span,
#cc_div div,
#cc_div h1,
#cc_div h2,
#cc_div h3,
#cc_div button,
#cc_div a,
#cc_div label,
#cc_div input{
    box-sizing: border-box;
    float: none;
    font-style: inherit;
    font-variant: normal;
    font-weight: inherit;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    text-transform: none;
    letter-spacing: unset;
    color: inherit;
}

#cc_div {
    font-size: 16px;
    font-weight: 400;
    font-family: "GTAmerica", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

#cc_div h1,
#cc_div h2,
#cc_div h3{
    font-weight: bold;
}

#cc_div input,
#cc_div button,
#cc_div a{
    width: auto;
    height: auto;
    line-height: initial;
    text-decoration: initial;
    border: none;
    outline: none;
    -webkit-appearance: none;
    appearance: none;
    font-family: inherit;
}

#cc_div .c_button,
#cc_div .c_link{
    padding: 1em 1.6em;
    margin-bottom: 0.625em;
    display: inline-block;
    box-shadow: 0 0 0 0 transparent;
    cursor: pointer;
    font-size: 0.85em;
    -webkit-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
        user-select: none;
    text-align: center;
    font-weight: bold;
}

#cc_div .c_anim .c_button,
#cc_div .c_anim .c_link{
    -webkit-transition: background-color .25s ease, color .25s ease, box-shadow .25s ease!important;
    transition: background-color .25s ease, color .25s ease, box-shadow .25s ease!important;
}

#cc_div #cm{
    font-family: inherit;
    padding: 1.2em 1.4em 1.3em;
    position: fixed;
    z-index: 100;
    background: #fff;
    color: #000;
    width: 100%;
    bottom: 0em;
    left: 0em;
    box-shadow: 0 0.625em 1.875em #000000;
    box-shadow: 0 0.625em 1.875em rgba(2, 2, 3, 0.28);
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(.95);
        -ms-transform: scale(.95);
            transform: scale(.95);
    line-height: initial;
}

#cc_div #cm.c_anim{
    -webkit-transition: opacity .3s ease, visibility .3s ease!important;
    transition: opacity .3s ease, visibility .3s ease!important;
}

#cc_div #cm.c_anim.cshow{
    opacity: 1;
    visibility: visible!important;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.ie #cm{
    border: 1px solid #f1f2f6;
}

#cc_div #cm_title{
    color: inherit;
    margin: 0;
    font-family: inherit;
    font-size: 1em;
}

#cc_div #cm_text{
    margin: 0;
    font-size: 0.9em;
    font-family: inherit;
    line-height: 1.45em;
    width: calc(94% - 20em);
    max-width: 60em;
    float: left;
}

#cc_div #cm .c_button,
#cc_div #cm .c_link{
    width: 30.5%;
}

#cc_div #cm button+button{
    float: right;
}

#cc_div .c_button,
#cc_div #cm .c_button,
#cc_div #cm .c_link{
    background: #ddd;
    color: #000;
}

#cc_div .c_button:hover,
#cc_div #cm .c_button:hover,
#cc_div #cm .c_link:hover,
#cc_div #cs_cont button + button:hover,
#cc_div #cs_close_btn:hover{
    background: #23454E;
    color: #fff;
}

.c_darkmode #cc_div #cm .c_link{
    background: #282b2e;
    color: #cccccc;
}

.c_darkmode #cc_div #cm .c_link:hover,
.c_darkmode #cc_div #cs_cont button + button:hover,
.c_darkmode #cc_div #cs_close_btn:hover{
    background: #3e454a;
}

#cc_div #cs_cont{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    background: #070707;
    background: rgba(11, 13, 14, 0.75);
    display: table;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: none!important;
    transition: none!important;
}

#cc_div #cm,
#cc_div #cs_cont{
    color: #000;
}

#cc_div #cs_cont button + button {
    float: right;
}

#cc_div #cs_blocks .title{
    margin-top: 1.4em;
}

#cc_div #cs_blocks .title:first-child{
    margin-top: 0;
}

#cc_div #cs_blocks .title h2{
    font-size: 1.1em;
}

#cc_div #cs_blocks .block_button{
    margin-top: 0;
    -webkit-transition: background-color .3s ease;
    transition: background-color .3s ease;
    cursor: pointer;
}

#cc_div #cs_blocks .block_button:hover{
    background-color: #aaa;
}

.c_darkmode #cc_div #cs_blocks .block_button:hover{
    background: #2b3035;
}

#cc_div #cs_blocks .accordion{
    margin-bottom: 0;
}

#cc_div #cs_blocks .accordion > p{
    margin-top: 0;
    padding: 1em;
}

#cc_div #cs_cont .block_button h2{
    display: table-cell;
    font-family: inherit;
    padding-right: 1em;
    vertical-align: middle;
}

#cc_div #cs_blocks .block_button{
    display: table;
    width: 100%;
    padding: 1.2em 1em;
}

#cc_div #cs_blocks .cs_block{
    padding: 1em;
    margin-bottom: 1em;
    border: 1px solid #f1f3f5;
    -webkit-transition: background-color .25s ease;
    transition: background-color .25s ease;
}
#cc_div #cs_blocks .cs_block:last-child{
    display: none;
    margin-bottom: .5em;
}

.c_darkmode #cc_div #cs_blocks .cs_block{
    border: 1px solid #373b40;
}

#cc_div #cs_blocks .cs_block:hover{
    background-color: #aaa;
}

.c_darkmode #cc_div #cs_blocks .cs_block:hover {
    background: #23272a;
}

#cc_div #cs_blocks .cs_block:first-child{
    -webkit-transition: none;
    transition: none;
    padding: 0;
    margin-top: 0;
    border:none;
    margin-bottom: 2em;
}

#cc_div #cs_blocks .cs_block:first-child:hover{
    background-color: transparent;
    background-color: unset;
}

#cc_div #cs_blocks .cs_block.block__expand{
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    border: none;
    margin-bottom: .5em;
    background: #eee;
    overflow: hidden;
    -webkit-transition: none;
    transition: none;
}

#cc_div #cs_blocks .cs_block.block__expand+div:not(.cs_block.block__expand){
    margin-top: 2em;
}

.cs_block.block__expand:first-child{
    margin-bottom: 1em;
}

.c_darkmode #cc_div #cs_blocks .cs_block.block__expand{
    background: #23272a;
}

#cc_div #cs_blocks .cs_block.block__expand:first-child{
    margin-bottom: .5em;
}

#cc_div #cs_blocks .cs_block.block__expand:first-child:hover{
    background: #aaa;
}

#cs_blocks div.block__expand:last-child{
    margin-bottom: 1.5em;
}

#cc_div #cs_blocks .cs_block.block__expand .b_title:after {
    content: " ▼";
    color: #666666;
    font-size: .875em;
    display: inline-block;
    margin-left: .5em;
}
#cc_div #cs_blocks .cs_block.block__expand._active .b_title:after {
    content: " ▲";
}

#cc_div #cs_blocks .accordion{
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
}

#cc_div #cs_blocks ._active .accordion,
#cc_div.ie #cs_blocks .accordion{
    max-height: 100%;
}

#cc_div #cs_cont p{
    font-size: 0.9em;
    line-height: 1.3em;
    margin-top: 1em;
}

#cc_div #cs_cont input{
    -webkit-appearance: none;
}

#cc_div #cs_cont.c_anim{
    -webkit-transition: background-color .3s ease, visibility .3s ease, opacity .3s ease!important;
    transition: background-color .3s ease, visibility .3s ease, opacity .3s ease!important;
}

#cc_div #cs_cont.c_anim.cshow{
    opacity: 1;
    visibility: visible!important;
}

#cc_div #cs_valign{
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

#cc_div #cs{
    margin: 0 auto;
    max-width: 49em;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(.96);
        -ms-transform: scale(.96);
            transform: scale(.96);
    -webkit-transition: opacity .3s ease,visibility .3s ease, -webkit-transform .3s ease!important;
    transition: opacity .3s ease,visibility .3s ease, -webkit-transform .3s ease!important;
    transition: transform .3s ease, opacity .3s ease,visibility .3s ease!important;
    transition: transform .3s ease, opacity .3s ease,visibility .3s ease, -webkit-transform .3s ease!important;
}

#cc_div.ie  #cs{
    max-height: 37.5em;
}

#cc_div.ie #cs_cont_inner{
    top: 0;
}

#cc_div #cs_inner{
    padding-top: 4.9375em;
    padding-bottom: 4.9375em;
    height: 100%;
    position: relative;
    margin: 0 1.250em;
    overflow: hidden;
}

#cc_div.ie #cs{
    position: relative;
    top: 0;
    margin-top: -5.625em;
}


#cc_div.ie #cs_valign{
    height: 100%;
    padding-top: 4.9375em;
}

#cc_div #cs_blocks{
    overflow-y: auto;
    overflow-y: overlay;
    overflow-x: hidden;
    height: 100%;
    position: relative;
    padding: 1.2em 1.4em 1.3em;
    padding-bottom: .5em;
}

#cc_div #cs_buttons{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1.2em 1.4em 1.3em;
    border-top: 1px solid #f1f1f1;
    height: 5.3375em;
}

#cc_div #cm .cc-link,
#cc_div #cs .cc-link{
    color: #111;
    border-bottom: solid 1px #444;
    display: inline;
    padding-bottom: 1px;
    text-decoration: none;
    font-weight: 400;
    opacity: 1;
}
#cc_div #cm .cc-link:hover,
#cc_div #cs .cc-link:hover{
    color: #23454E;
    border-bottom: transparent;
}

.c_darkmode #cc_div #cm .cc-link,
.c_darkmode #cc_div #cs .cc-link{
    color: #b7cada;
    border-color: #b7cada;
}

#cc_div #cs_inner,
#cc_div #cs_header,
#cc_div #cs_buttons{
    background-color: #fff;
}

.c_darkmode #cc_div .c_button,
.c_darkmode #cc_div #cm .c_button{
    background: #33383c;
    color: #ffffff;
}

#cc_div #cm #cm_btns {
    width: 20em;
    float: right;
}

#cc_div #cm button {
    margin-left: 9px;
}

#cc_div #cm #cm_settings_btn {
    background: #fff;
    color: #000;
}
#cc_div #cm #cm_settings_btn:hover {
    background: #23454E;
    color: #fff;
}

#cc_div #cs_header{
    position: absolute;
    top: 0;
    width: 100%;
    display: table;
    padding: 1.2em 1.4em 1.3em;
    vertical-align: middle;
    z-index: 2;
    border-bottom: 1px solid #f1f1f1;
}

#cc_div #cs_title{
    display: table-cell;
    vertical-align: middle;
    font-size: 1.75em;
    font-family: inherit;
}

#cc_div #cs_title h1{
    font-size: 1em;
}

#cc_div #cs_close_btn{
    display: table-cell;
    float: right;
    vertical-align: middle;
    padding: 0;
    width: 2.7em;
    height: 2.7em;
    border: none;
    font-size: 1em;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    margin: 0;
    border-radius: 50%;
    background: #ddd;
    color: transparent;
}
#cc_div #cs_close_btn:before{
    content: "×";
    color: #000;
    font-size: 1.5em;
    position:relative;
    left: .2em;
    line-height: 0;
}
#cc_div #cs_close_btn:hover{
    background: #444;
    color: transparent;
    &:before {
        color: #fff;
    }
}

#cc_div #c_policy__text{
    height: 31.250em;
    overflow-y: auto;
    margin-top: 1.250em;
}

#cc_div .c_anim.cshow #cs{
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

#cc_div #cs_cont_inner{
    height: 100%;
    position: relative;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    height: calc(100% - 2.5em);
    overflow: hidden;
    max-height: 37.5em;
}

#cc_div #cs_blocks::-webkit-scrollbar{
    width: 1em;
    height: 100%;
    background-color: transparent;
}

#cc_div #cs_blocks::-webkit-scrollbar-thumb{
    border: 0.3em solid white;
    background-color: #cfd5db;
 border-radius: 100em;
}

#cc_div #cs_blocks::-webkit-scrollbar-thumb:hover{
    background-color: #9199a0;
}

#cc_div #cs_blocks::-webkit-scrollbar-button {
    width: 10px;
    height: 5px;
    opacity: 0;
    visibility: hidden;
}

#cc_div #cs_blocks::-webkit-scrollbar-corner {
    background-color: transparent;
}

#cc_div .c_b_toggle {
    display: table-cell;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
}

#cc_div.ie .c_b_toggle{
    padding-left: 1em;
    margin-bottom: 0.7em;
}

#cc_div .c_b_toggle input {
    position: absolute;
    cursor: pointer;
    display: none;
}

#cc_div .c_b_toggle .sc_toggle {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 4em;
    background-color: #666666;
    -webkit-transition: background-color .25s ease;
    transition: background-color .25s ease;
}
#cc_div .c_b_toggle .sc_toggle:hover {
    background-color: #333333;
}

#cc_div .c_b_toggle,
#cc_div .c_b_toggle .sc_toggle{
    width: 5em;
    height: 1.6em;
}

#cc_div .c_b_toggle .sc_toggle.sc_readonly{
    cursor: not-allowed;
}

#cc_div .c_b_toggle input ~ .sc_toggle.sc_readonly{
    background-color: #d5dee2;
}

#cc_div .c_b_toggle input ~ .sc_toggle.sc_readonly:after{
    box-shadow: none;
}

#cc_div .c_b_toggle .sc_toggle:after {
    content: "off";
    text-align: center;
    color: #777777;
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    width: 2.2em;
    height: 1.2em;
    padding-right: .1em;
    border: none;
    box-sizing: content-box;
    background: #fff;
    box-shadow: 0 1px 2px rgba(24, 32, 35, .36);
    -webkit-transition: background-color .3s ease, -webkit-transform .3s ease;
    transition: background-color .3s ease, -webkit-transform .3s ease;
    transition: transform .3s ease, background-color .3s ease;
    transition: transform .3s ease, background-color .3s ease, -webkit-transform .3s ease;
    border-radius: .6em;
}

#cc_div .c_b_toggle input:checked ~ .sc_toggle {
    background-color: #111111;
}

#cc_div .c_b_toggle input:checked ~ .sc_toggle:after {
    -webkit-transform: translateX(2.4em);
        -ms-transform: translateX(2.4em);
            transform: translateX(2.4em);
    content: "on";
    color: #000000;
    padding-right: 0;
    background: #05d192;
}

#cc_div.ie .c_b_toggle input:checked ~ .sc_toggle:after{
    left: 1.625em;
    content: "on";
    color: #000000;
}

#cc_div #cs_blocks table,
#cc_div #cs_blocks table th,
#cc_div #cs_blocks table td{
    border: none;
}

#cc_div #cs_blocks table tbody tr{
    -webkit-transition: background-color .3s ease;
    transition: background-color .3s ease;
}

#cc_div #cs_blocks table tbody tr:hover{
    background-color: #aaa;
}

.c_darkmode #cc_div #cs_blocks table tbody tr:hover{
    background-color: #2b3035
}

#cc_div #cs_blocks table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
#cc_div.ie #cs_blocks table{
    overflow: auto;
}

#cc_div #cs_blocks table td,
#cc_div #cs_blocks table th {
    padding: 0.8em 0.625em;
    text-align: left;
    vertical-align: top;
    font-size: .8em;
    padding-left: 1.2em;
    line-height: 1.5em;
    padding-bottom: 2em;
}

#cc_div #cs_blocks table th {
    font-family: inherit;
    padding: 1.2em 1.2em;
    color: #23454E;
}

#cc_div #cs_blocks thead tr:first-child{
    border-bottom: 1px solid #e6e8ed;
}
.c_darkmode #cc_div #cs_blocks thead tr:first-child{
    border-color: #2b3035;
}

.c_darkmode #cc_div #cm,
.c_darkmode #cc_div #cs {
    color: #fff;
}

.c_darkmode #cc_div #cs_cont p,
.c_darkmode #cc_div #cs_blocks table td {
    color: #c5cbd0;
}

.c_darkmode #cc_div #cm {
    background: #151619;
    box-shadow: 0 0 1.875em rgba(0, 0, 0, .56);
}

.c_darkmode #cm #cm_text {
    color: #d4d8d9;
}

.c_darkmode #cc_div #cs_inner,
.c_darkmode #cc_div #cs_header,
.c_darkmode #cc_div #cs_buttons {
    background: #181b1d;
}

.c_darkmode #cc_div #cs_header,
.c_darkmode #cc_div #cs_buttons {
    border-color: #292d31;
}

.c_darkmode #cc_div .c_b_toggle .sc_toggle:after {
    background: #23272a;
}

.c_darkmode #cc_div .c_b_toggle input:checked~.sc_toggle.sc_readonly {
    background-color: #4b4e53;
}

.c_darkmode #cc_div #cs_blocks::-webkit-scrollbar-thumb {
    border: 0.3em solid #181b1d;
    background-color: #3d4349;
}

.c_darkmode #cc_div #cs_blocks::-webkit-scrollbar-thumb:hover {
    background-color: #535a5f;
}

.c_darkmode #cc_div #cs_blocks table td:before {
    color: #fff;
}

.c_darkmode  #cc_div.ie #cs_blocks table{
    border: 1px solid #232529;
}

@media screen and (max-width: 900px){
    #cc_div #cm{
        width: auto;
        max-width: 100%;
        margin: 0;
        padding: 1.875em 10%;
        padding: 1.2em 1.4em 1.3em;
        right: 0;
        bottom: 0;
        border-radius: 0;
        display: block;
    }
    #cc_div #cm_text {
        margin-bottom: 1.2em;
    }
}

@media screen and (max-width: 688px) {
    #cc_div #cs{
        border-radius: 0;
    }

    #cc_div #cs_cont_inner{
        max-height: 100%;
        height: 100%;
        top: 0;
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
    }

    #cc_div .c_b_toggle{
        font-size: 1.25em;
    }

    #cc_div #cs_cont button + button{
        margin-bottom: 0;
    }

    #cc_div #cs_inner{
        margin: 0;
        border-radius: 0;
        padding-bottom: 7.8em;
    }

    #cc_div #cs_cont .block_button h2{
        font-size: 1em;
    }

    #cc_div #cs_blocks{
        padding: 1.3em;
    }

    #cc_div #cs_header{
        padding: 1.2em 1.3em;
    }

    #cc_div #cm_text{
        width: 100%;
    }

    #cc_div #cs_buttons{
        height: 7.8em;
        padding: 1em 1.3em;
    }

    #cc_div #cs_blocks table {
        width: 100%;
    }

    #cc_div #cs_blocks table,
    #cc_div #cs_blocks table thead,
    #cc_div #cs_blocks table tbody,
    #cc_div #cs_blocks table th,
    #cc_div #cs_blocks table td,
    #cc_div #cs_blocks table tr {
        display: block;
    }


    #cc_div #cs_blocks table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        display: none;
    }

    #cc_div #cs_blocks table td {
        border: none;
        position: relative;
        padding-left: 40%;
    }

    #cc_div #cs_blocks table td:before {
        position: absolute;
        left: 1em;
        width: 43%;
        padding-right: 0.625em;
        white-space: nowrap;
        content: attr(data-column);
        color: #000;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    #cc_div #cm_btns button:last-child{
        margin-bottom: 0;
    }
}

@media screen and (max-width: 360px) {
    #cc_div #cm #cm_btns {
        width: 100%;
    }
    #cc_div #cm .c_button,
    #cc_div .c_button,
    #cc_div #cm .c_link{
        width: 100%;
        margin-right: 0;
    }
    #cc_div #cm button,
    #cc_div #cs_buttons button {
        margin-left: 0;
    }
}

button#cs_acceptall_btn {
    display: none !important;
}
