#header__mobile {
    position: fixed;
    top: 32px;
    left: 0px;
    width: 100%;
    background-color: #FAD358;
    color: #000000;
    border-bottom: 1px solid #23454E;
    z-index: 99;
    padding-left: 1.25rem;
}

#jra-logo {
    float: left;
    display: inline;
    margin-top: .75rem;
    margin-bottom: .75rem;
    height: 1.65rem;
}

#menu-hamburger {
    cursor: pointer;
    float: right;
    height: 1.25rem;
    width: 1.25rem;
    margin-top: .75rem;
    margin-bottom: .75rem;
    margin-right: 1.25rem;
}

#header__mobile--menu {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #FAD358;
    color: #000000;
    z-index: 99;
    padding-left: 2rem;
}

#header__mobile--menu-close {
    position: absolute;
    right: 1.3rem;
    top: 2.5rem;
}

#header__mobile--menu ul {
    margin: 0;
    padding: 0;
    padding-top: 5rem;
}

#header__mobile--menu li { 
    margin-bottom: 3rem;
}

#header__mobile--menu li a,
#header__mobile--menu li button {
    display: block;
    margin-left: 1.25rem;
    margin-top: .75rem;
    margin-bottom: 3rem;
}